const addObjectToState = (objects, object, idName) => {
  objects = objects.slice()
  if (!object) {
    return objects
  }

  const isObjectInstate = objects.filter(o => o[idName] === object[idName])

  if (isObjectInstate.length > 0) {
    objects.forEach((o, i) => {
      if (o[idName] === object[idName]) objects[i] = object
    })
  } else {
    objects.push(object)
  }
  return objects
}

export default addObjectToState
