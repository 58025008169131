import React from 'react'
import ReactDOM from 'react-dom'
import rootReducer from './reducers'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import App from './components/App'
import * as serviceWorker from './serviceWorker'

const store = createStore(rootReducer, applyMiddleware(thunk))

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
