import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'

import AppBar from '@material-ui/core/AppBar'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Skeleton from '@material-ui/lab/Skeleton'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import Header from '../../Header'
import ModalNoteForm from './ModalNoteForm'
import ModalConfirmationDialog from '../../ModalConfirmationDialog'

import { getNotes, noteDelete } from 'actions'

const styles = theme => ({
  icon: {
    color: '#fff',
    marginRight: '10px',
    position: 'relative',
    top: '5px',
  },
  appointmentDate: {
    color: '#999',
    fontWeight: 'bold',
    fontSize: '0.75em',
    textTransform: 'uppercase',
  },
  noteContainer: {
    '& .navbar button': {
      marginRight: '5px',
      color: '#ddd',
    },
    '&:hover .navbar button': {
      color: '#666',
    },
  },
})

class ClientNotes extends React.Component {
  state = {
    open: false,
    confirmationOpen: false,
    note: {},
  }

  handleOpen = note => () => this.setState({ open: true, note: note })

  handleClose = () => this.setState({ open: false, note: {} })

  componentDidMount() {
    const client_id = parseInt(this.props.match.params.id)
    const notes = this.props.notes.filter(note => note.client_id === client_id)
    if (notes.length === 0) {
      this.props.dispatch(getNotes(client_id))
    }
  }

  handleDeleteConfirmation = note => () =>
    this.setState({ confirmationOpen: true, note: note })

  handleConfirmationClose = () =>
    this.setState({ confirmationOpen: false, note: {} })

  handleDelete = () => {
    this.props
      .dispatch(noteDelete(this.state.note.client_id, this.state.note.note_id))
      .then(() => {
        this.props.enqueueSnackbar('Note deleted successfully.', {
          variant: 'info',
          autoHideDuration: 5000,
        })
        this.handleConfirmationClose()
      })
  }

  render() {
    const client_id = parseInt(this.props.match.params.id)
    const client =
      this.props.clients.filter(client => client.client_id === client_id)[0] ||
      {}
    const notes = this.props.notes.filter(note => note.client_id === client_id)

    const notesLoadComponent = (
      <Box p={3}>
        <Skeleton variant="rect" width="100%" height={50} />
        <Box pt={2}>
          <Skeleton variant="rect" width="60%" />
        </Box>
      </Box>
    )

    const notesComponent = (
      <Box p={3}>
        {notes.map((note, index) => {
          const appointment =
            this.props.appointments.filter(
              app => app.appointment_id === note.appointment_id
            )[0] || {}
          return (
            <React.Fragment key={note.note_id}>
              <Box className={this.props.classes.noteContainer}>
                <Box pt={index > 0 ? 3 : 0} className="navbar">
                  <IconButton
                    aria-label="Edit"
                    size="small"
                    onClick={this.handleOpen(note)}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    aria-label="Delete"
                    size="small"
                    onClick={this.handleDeleteConfirmation(note)}
                  >
                    <DeleteOutlineIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <Box pt={2} pb={2}>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className={this.props.classes.appointmentDate}
                  >
                    {appointment.date_representation}
                  </Typography>
                  <Typography variant="body1" component="p">
                    {note.content}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            </React.Fragment>
          )
        })}
      </Box>
    )

    return (
      <React.Fragment>
        <Header>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography variant="h6" color="inherit" noWrap>
                <Link component={RouterLink} to="/console/clients">
                  <ArrowBackIosIcon className={this.props.classes.icon} />
                </Link>
                {client.first_name} {client.last_name}
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <ModalNoteForm
                client={client}
                title="Adaugă notiță"
                successMessage="Note added successfully"
              />
            </Grid>
          </Grid>
        </Header>

        <Box p={3}>
          <Paper>
            <AppBar position="static" color="default" elevation={0}>
              <Tabs variant="fullWidth" value={1} indicatorColor="primary">
                <Tab
                  component={RouterLink}
                  to={`/console/clients/${client_id}`}
                  label="Date generale"
                  id="nav-tab-1"
                ></Tab>
                <Tab
                  label="Note"
                  id="nav-tab-2"
                  component={RouterLink}
                  to={`/console/clients/${client_id}/notes`}
                ></Tab>
              </Tabs>
            </AppBar>
            {this.props.isGetFetching ? notesLoadComponent : notesComponent}
            <ModalNoteForm
              open={this.state.open}
              client={client}
              note={this.state.note}
              handleClose={this.handleClose}
              key={this.state.note.note_id}
              title="Editează notiță"
              successMessage="Note edited successfully"
              hideTriggerButton
            />
            <ModalConfirmationDialog
              open={this.state.confirmationOpen}
              handleClose={this.handleConfirmationClose}
              handleSubmit={this.handleDelete}
              title="Șterge notiță?"
              isFetching={this.props.isDeleteFetching}
              message="Are you sure you want to delete this note?"
            />
          </Paper>
        </Box>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({
  client: { clients },
  note: { notes, isGetFetching, isDeleteFetching },
  appointment: { appointments },
}) => ({
  clients,
  notes,
  appointments,
  isGetFetching,
  isDeleteFetching,
})

ClientNotes = connect(mapStateToProps)(ClientNotes)
ClientNotes = withSnackbar(ClientNotes)

export default withStyles(styles)(ClientNotes)
