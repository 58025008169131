import { combineReducers } from 'redux'

import { appointment } from './appointment'
import { client } from './client'
import { console } from './console'
import { note } from './note'
import { signup, login, human } from './auth'

import * as Type from '../actions'

const app = (
  state = {
    user: undefined,
  },
  action
) => {
  switch (action.type) {
    case Type.USER_ACCOUNT_DETAILS_REQUEST:
      return { ...state, user: undefined }
    case Type.USER_SIGNUP_SUCCESS:
    case Type.USER_LOGIN_SUCCESS:
    case Type.USER_ACCOUNT_DETAILS_SUCCESS:
      return { ...state, user: action.user }
    case Type.USER_ACCOUNT_DETAILS_FAILED:
      return { ...state, user: null }
    case Type.USER_LOGOUT:
      return { ...state, user: null }
    default:
      return state
  }
}

export default combineReducers({
  app,
  appointment,
  client,
  console,
  login,
  signup,
  human,
  note,
})
