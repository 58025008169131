import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import CenteredPaper from '../Core/CenteredPaper'
import ThemeBackground from '../Core/ThemeBackground'
import SignupForm from './SignupForm'

const styles = theme => ({
  root: {
    minHeight: '100vh',
  },
  formRoot: {
    paddingTop: theme.spacing(3),
  },
  textContainer: {
    marginTop: theme.spacing(3),
  },
  subtitle: {
    fontSize: '1.2rem',
  },
})

const Signup = ({ classes }) => (
  <Grid container className={classes.root}>
    <ThemeBackground />

    <CenteredPaper>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Începe o experiență nouă cu SemperNote
      </Typography>
      <Typography
        component="h2"
        variant="subtitle1"
        align="center"
        color="textSecondary"
        gutterBottom
      >
        Încearcă SemperNote gratuit pentru 30 de zile
      </Typography>

      <Divider />

      <Grid container className={classes.formRoot} spacing={5}>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            spacing={3}
            className={classes.textContainer}
          >
            <Grid item>
              <Typography
                component="h3"
                className={classes.subtitle}
                gutterBottom
                color="primary"
              >
                O platformă integrată pentru psihologi
              </Typography>
              <Typography variant="body1" gutterBottom>
                Cu SemperNote ai tot ce-ți trebuie
                pentru a-ți gestiona cabinetul de psiholog într-un singur loc
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                component="h3"
                className={classes.subtitle}
                gutterBottom
                color="primary"
              >
                Acces ușor oriunde și oricând
              </Typography>
              <Typography variant="body1" gutterBottom>
                Poți accesa informațiile despre clienți și programări
                într-un singur loc, indiferent că ești la cabinet,
                te pregătești pentru o ședință de acasă
                sau te contactează urgent un client în timp ce ești în oraș
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                component="h3"
                className={classes.subtitle}
                gutterBottom
                color="primary"
              >
                Confidențialitate și securitatea datelor
              </Typography>
              <Typography variant="body1" gutterBottom>
                SemperNote respectă standardele GDPR
                de procesare a datelor electronice
                și integrează cele mai noi metode de criptare și securitate
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <SignupForm />
        </Grid>
      </Grid>
    </CenteredPaper>
  </Grid>
)

export default withStyles(styles)(Signup)
