export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST'
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS'
export const USER_SIGNUP_FAILED = 'USER_SIGNUP_FAILED'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'

export const USER_ACCOUNT_DETAILS_REQUEST = 'USER_ACCOUNT_DETAILS_REQUEST'
export const USER_ACCOUNT_DETAILS_SUCCESS = 'USER_ACCOUNT_DETAILS_SUCCESS'
export const USER_ACCOUNT_DETAILS_FAILED = 'USER_ACCOUNT_DETAILS_FAILED'

export const USER_LOGOUT = 'USER_LOGOUT'

export const OPEN_DRAWER = 'OPEN_DRAWER'
export const CLOSE_DRAWER = 'CLOSE_DRAWER'

export const CLIENTS_REQUEST = 'CLIENTS_REQUEST'
export const CLIENTS_REQUEST_SUCCESS = 'CLIENTS_REQUEST_SUCCESS'
export const CLIENTS_REQUEST_FAILED = 'CLIENTS_REQUEST_FAILED'

export const CLIENT_CREATE_REQUEST = 'CLIENT_CREATE_REQUEST'
export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST'
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS'
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS'
export const CLIENT_CREATE_FAILED = 'CLIENT_CREATE_FAILED'

export const APPOINTMENTS_GET_REQUEST = 'APPOINTMENT_GET_REQUEST'
export const APPOINTMENTS_GET_SUCCESS = 'APPOINTMENT_GET_SUCCESS'
export const APPOINTMENTS_GET_FAILED = 'APPOINTMENT_GET_FAILED'

export const APPOINTMENT_CREATE_REQUEST = 'APPOINTMENT_CREATE_REQUEST'
export const APPOINTMENT_CREATE_SUCCESS = 'APPOINTMENT_CREATE_SUCCESS'
export const APPOINTMENT_CREATE_FAILED = 'APPOINTMENT_CREATE_FAILED'
export const APPOINTMENT_DELETE_REQUEST = 'APPOINTMENT_DELETE_REQUEST'
export const APPOINTMENT_DELETE_SUCCESS = 'APPOINTMENT_DELETE_SUCCESS'

export const RECAPTCHA_REQUEST = 'RECAPTCHA_REQUEST'
export const RECAPTCHA_VERIFY_SUCCESS = 'RECAPTCHA_VERIFY_SUCCESS'
export const RECAPTCHA_RESET = 'RECAPTCHA_RESET'

export const NOTE_CREATE_REQUEST = 'NOTE_CREATE_REQUEST'
export const NOTE_CREATE_SUCCESS = 'NOTE_CREATE_SUCCESS'
export const NOTE_CREATE_FAILED = 'NOTE_CREATE_FAILED'

export const NOTES_REQUEST = 'NOTES_REQUEST'
export const NOTES_REQUEST_SUCCESS = 'NOTES_REQUEST_SUCCESS'
export const NOTES_REQUEST_FAILED = 'NOTES_REQUEST_FAILED'
export const NOTE_DELETE_SUCCESS = 'NOTE_DELETE_SUCCESS'
export const NOTE_DELETE_REQUEST = 'NOTE_DELETE_REQUEST'
