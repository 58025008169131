import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import Console from './Console'
import Login from './Auth/Login'
import Logout from './Auth/Logout'
import PrivateRoute from './Core/PrivateRoute'
import Signup from './Auth/Signup'
import ResetPassword from './Auth/ResetPassword'

import { UserContext } from '../context'

let Routes = ({ user }) => (
  <UserContext.Consumer>
    {user => (
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              user ? <Redirect to="/console" /> : <Redirect to="/login" />
            }
          />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/logout" component={Logout} />
          <PrivateRoute path="/console" component={Console} />
        </Switch>
      </Router>
    )}
  </UserContext.Consumer>
)

export default Routes
