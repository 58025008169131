import React, { Component } from 'react'
import { SnackbarProvider } from 'notistack'

import CssBaseline from '@material-ui/core/CssBaseline'
import { connect } from 'react-redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Routes from './Routes'

import { UserContext } from '../context'
import { userAccountDetails } from '../actions'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#534bae',
      main: '#1a237e',
      dark: '#000051',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f05545',
      main: '#b71c1c',
      dark: '#7f0000',
      contrastText: '#fff',
    },
  },
})

class App extends Component {
  componentDidMount() {
    this.props.dispatch(userAccountDetails())
  }

  render() {
    const { user } = this.props
    return (
      <SnackbarProvider>
        <MuiThemeProvider theme={theme}>
          <UserContext.Provider value={user}>
            <CssBaseline />
            {this.props.user !== undefined ? <Routes /> : ''}
          </UserContext.Provider>
        </MuiThemeProvider>
      </SnackbarProvider>
    )
  }
}

const mapStateToProps = ({ app: { user } }) => ({ user })
App = connect(mapStateToProps)(App)

export default App
