import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'

import Header from '../../Header'

const styles = theme => ({
  icon: {
    color: '#fff',
    marginRight: '10px',
    position: 'relative',
    top: '5px',
  },
})

class ClientDetails extends React.Component {
  render() {
    const client_id = this.props.match.params.id
    const client =
      this.props.clients.filter(client => client.client_id === client_id)[0] ||
      {}
    return (
      <React.Fragment>
        <Header>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography variant="h6" color="inherit" noWrap>
                <Link component={RouterLink} to="/console/clients">
                  <ArrowBackIosIcon className={this.props.classes.icon} />
                </Link>
                {client.first_name} {client.last_name}
              </Typography>
            </Grid>
          </Grid>
        </Header>

        <Box p={3}>
          <Paper>
            <AppBar position="static" color="default" elevation={0}>
              <Tabs variant="fullWidth" value={0} indicatorColor="primary">
                <Tab
                  component={RouterLink}
                  to={`/console/clients/${client_id}`}
                  label="Date generale"
                  id="nav-tab-1"
                ></Tab>
                <Tab
                  label="Note"
                  id="nav-tab-2"
                  component={RouterLink}
                  to={`/console/clients/${client_id}/notes`}
                ></Tab>
              </Tabs>
            </AppBar>

            <Box p={1}>
              <p>content</p>
            </Box>
          </Paper>
        </Box>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ client: { clients } }) => ({ clients })

ClientDetails = connect(mapStateToProps)(ClientDetails)

export default withStyles(styles)(ClientDetails)
