import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Header from '../Header'
import { UserContext } from 'context'

const styles = theme => ({
  image: {
    position: 'relative',
    top: '5px',
    width: '30px',
    height: '30px',
    fontSize: '1rem',
    background: theme.palette.primary.main,
  },
})

class AccountSettings extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Header>
          <Typography variant="h6" color="inherit" noWrap>
            Setări
          </Typography>
        </Header>

        <Box p={3}>
          <Paper>
            <Box p={3}>
              <Grid container>
                <Grid item xs={4} md={2}>
                  <Typography variant="h6">Profil</Typography>
                </Grid>
                <Grid item xs={8} md={10}>
                  <UserContext.Consumer>
                    {user => (
                      <Grid container style={{ paddingBottom: '10px' }}>
                        <Avatar className={classes.image}>
                          {user.first_name.substring(0, 1).toUpperCase()}
                          {user.last_name.substring(0, 1).toUpperCase()}
                        </Avatar>
                        <Grid style={{ paddingLeft: '20px' }}>
                          <Typography variant="subtitle2">
                            {user.first_name} {user.last_name}
                          </Typography>
                          <Typography variant="body2">{user.email}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </UserContext.Consumer>

                  <Divider light />

                  <List dense>
                    <ListItem disableGutters>
                      <Link>Updatare date personale</Link>
                    </ListItem>
                    <ListItem disableGutters>
                      <Link>Schimbare email</Link>
                    </ListItem>
                    <ListItem disableGutters>
                      <Link
                        component={RouterLink}
                        to="/console/settings/password/change"
                      >
                        Schimbare parolă
                      </Link>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ app: { user } }) => ({ user })
AccountSettings = connect(mapStateToProps)(AccountSettings)
AccountSettings = withSnackbar(AccountSettings)

export default withStyles(styles)(AccountSettings)
