import * as Type from './types'
import axios from 'axios'

const getNotesRequest = { type: Type.NOTES_REQUEST }

const getNotesRequestFailed = { type: Type.NOTES_REQUEST_FAILED }

const getNotesRequestSuccess = (client_id, notes) => ({
  type: Type.NOTES_REQUEST_SUCCESS,
  client_id,
  notes,
})

export const getNotes = client_id => async dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/clients/${client_id}/notes`
  const authToken = window.localStorage.getItem('authToken')

  dispatch(getNotesRequest)

  try {
    const response = await axios.get(url, {
      headers: { Authorization: `JWT ${authToken}` },
    })
    const notes = response.data.result.notes

    dispatch(getNotesRequestSuccess(client_id, notes))
  } catch (error) {
    dispatch(getNotesRequestFailed)
  }
}

const noteCreateRequest = { type: Type.NOTE_CREATE_REQUEST }
const noteCreateSuccess = note => ({
  type: Type.NOTE_CREATE_SUCCESS,
  note,
})
const noteCreateFailed = errors => ({
  type: Type.NOTE_CREATE_FAILED,
  errors,
})

const noteDeleteRequest = { type: Type.NOTE_DELETE_REQUEST }
const noteDeleteSuccess = note_id => ({
  type: Type.NOTE_DELETE_SUCCESS,
  note_id,
})
export const noteDelete = (client_id, note_id) => dispatch => {
  let url = `${process.env.REACT_APP_API_URL}/clients/${client_id}/notes/${note_id}`
  const authToken = window.localStorage.getItem('authToken')

  dispatch(noteDeleteRequest)
  return axios
    .delete(url, {
      headers: { Authorization: `JWT ${authToken}` },
    })
    .then(() => dispatch(noteDeleteSuccess(note_id)))
}

export const noteCreate = (client_id, data, successCallback) => dispatch => {
  let url = `${process.env.REACT_APP_API_URL}/clients/${client_id}/notes`
  const authToken = window.localStorage.getItem('authToken')

  const note_id = data.note_id
  const request = note_id ? axios.put : axios.post
  url = note_id ? `${url}/${note_id}` : url
  delete data.note_id

  dispatch(noteCreateRequest)

  return request(url, data, {
    headers: { Authorization: `JWT ${authToken}` },
  })
    .then(response => {
      dispatch(noteCreateSuccess(response.data.result.note))
      successCallback()
    })
    .catch(error => {
      const errors = error.response.data.result.errors
      dispatch(noteCreateFailed(errors))
    })
}

