import React from 'react'
import moment from 'moment'
import BigCalendar from 'react-big-calendar'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import Header from '../Header'
import ModalAppointmentForm from './ModalAppointmentForm'
import ModalConfirmationDialog from '../ModalConfirmationDialog'
import ModalViewAppointment from './ModalViewAppointment'

import { appointmentDelete } from '../../../actions'

import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = BigCalendar.momentLocalizer(moment)

const styles = theme => ({
  root: {
    height: 'calc(100vh - 64px)',
    position: 'relative',
    padding: '20px',
    fontFamily: 'Roboto',
  },
})

class Calendar extends React.Component {
  state = {
    openAppointment: false,
    openEditAppointment: false,
    confirmationOpen: false,
    currentAppointment: {},
    currentClient: {},
    currentEvent: {},
  }

  handleEditDialog = event => {
    this.setState({ openEditAppointment: true, openAppointment: false })
  }

  handleOnSelectEvent = event => {
    const currentAppointment = this.props.appointments.filter(
      appointment => appointment.appointment_id === event.id
    )[0]
    const currentClient = this.props.clients.filter(
      client => client.client_id === currentAppointment.client_id
    )[0]
    this.setState({
      openAppointment: true,
      currentAppointment: currentAppointment,
      currentEvent: event,
      currentClient: currentClient,
    })
  }

  handleCloseAppointment = () => {
    this.setState({
      openAppointment: false,
      currentAppointment: {},
      currentEvent: {},
      currentClient: {},
    })
  }

  handleCloseEditAppointment = () => {
    this.setState({
      openAppointment: false,
      openEditAppointment: false,
      currentAppointment: {},
      currentEvent: {},
      currentClient: {},
    })
  }

  handleDeleteConfirmation = () =>
    this.setState({
      confirmationOpen: true,
      openAppointment: false,
      openEditAppointment: false,
    })

  handleConfirmationClose = () =>
    this.setState({
      openAppointment: false,
      openEditAppointment: false,
      confirmationOpen: false,
      currentAppointment: {},
      currentEvent: {},
      currentClient: {},
    })

  handleDelete = () => {
    this.props
      .dispatch(appointmentDelete(this.state.currentAppointment))
      .then(() => {
        this.props.enqueueSnackbar('Appointment deleted successfully.', {
          variant: 'info',
          autoHideDuration: 5000,
        })
        this.handleConfirmationClose()
      })
  }
  render() {
    const { classes, appointments } = this.props
    const events = appointments.map(appointment => {
      const startTime = new Date(appointment.start_time)
      const endTime = new Date(appointment.end_time)

      const utcStartTime = new Date(
        startTime.getUTCFullYear(),
        startTime.getUTCMonth(),
        startTime.getUTCDate(),
        startTime.getUTCHours(),
        startTime.getUTCMinutes(),
        startTime.getUTCSeconds()
      )
      const utcEndTime = new Date(
        endTime.getUTCFullYear(),
        endTime.getUTCMonth(),
        endTime.getUTCDate(),
        endTime.getUTCHours(),
        endTime.getUTCMinutes(),
        endTime.getUTCSeconds()
      )

      return {
        id: appointment.appointment_id,
        title: appointment.title,
        start: utcStartTime,
        end: utcEndTime,
        color: appointment.color_id,
      }
    })

    const eventPropGetter = event => ({
      style: {
        backgroundColor: event.color,
        fontSize: '0.85rem',
        padding: '3px 15px',
        borderRadius: '25px',
      },
    })

    return (
      <React.Fragment>
        <Header>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography variant="h6" color="inherit" noWrap>
                Calendar
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <ModalAppointmentForm
                title="Adaugă programare."
                successMessage="Appointment added successfully."
              />
            </Grid>
          </Grid>
        </Header>

        <main className={classes.root}>
          <BigCalendar
            localizer={localizer}
            events={events}
            eventPropGetter={eventPropGetter}
            onSelectEvent={this.handleOnSelectEvent}
          />
          <ModalViewAppointment
            open={this.state.openAppointment}
            appointment={this.state.currentAppointment}
            client={this.state.currentClient}
            event={this.state.currentEvent}
            handleClose={this.handleCloseAppointment}
            handleEditDialog={this.handleEditDialog}
            handleDeleteConfirmation={this.handleDeleteConfirmation}
          />
          <ModalAppointmentForm
            open={this.state.openEditAppointment}
            appointment={this.state.currentAppointment}
            event={this.state.currentEvent}
            key={this.state.currentAppointment.appointment_id}
            title="Editează programare"
            handleClose={this.handleCloseEditAppointment}
            successMessage="Appointment edited successfully."
            hideTriggerButton
          />
          <ModalConfirmationDialog
            open={this.state.confirmationOpen}
            handleClose={this.handleConfirmationClose}
            handleSubmit={this.handleDelete}
            title="Șterge appointment"
            isFetching={this.props.isDeleteFetching}
            message={`Are you sure you want to delete ${this.state.currentAppointment.title} appointment?`}
          />
        </main>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({
  appointment: { appointments, isDeleteFetching },
  client: { clients },
}) => ({ appointments, clients, isDeleteFetching })
Calendar = connect(mapStateToProps)(Calendar)
Calendar = withSnackbar(Calendar)

export default withStyles(styles)(Calendar)
