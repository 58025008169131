import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const styles = theme => ({
  cover: {
    position: 'fixed',
    top: 0,
    left: 0,
    minHeight: '100%',
    width: '100%',
    height: 'auto',
    zIndex: '-1',
  },
})

class ThemeBackground extends Component {
  render() {
    const backgroundStyle = {
      background: 'linear-gradient(to top right, #63b6dd, #de80ad)',
      backgroundSize: 'cover',
    }
    return <div style={backgroundStyle} className={this.props.classes.cover} />
  }
}

export default withStyles(styles)(ThemeBackground)
