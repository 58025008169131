import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { userSignup, recaptchaVerify, recaptchaRequest } from '../../actions';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

const styles = theme => ({
  submit: {
    marginTop: theme.spacing(3),
  },
})

class SignupForm extends Component {
  state = {
    formData: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  }

  async componentDidMount() {
    await this.triggerRecaptcha()
  }

  async componentDidUpdate(prevProps) {
    if (this.props.recaptchaToken == null && !this.props.recaptchaIsProcessing) {
      await this.triggerRecaptcha();
    }
  }

  async triggerRecaptcha() {
    this.props.dispatch(recaptchaRequest())
    const token = await this.props.googleReCaptchaProps.executeRecaptcha('signup')
    this.props.dispatch(recaptchaVerify(token))
  }

  handleChange = name => e => {
    const obj = this.state.formData
    obj[name] = e.target.value
    this.setState(obj)
  }

  handleSubmit = async e => {
    e.preventDefault()

    const data = {
      user: {
        first_name: this.state.formData.firstName,
        last_name: this.state.formData.lastName,
        email: this.state.formData.email,
      },
      password: this.state.formData.password,
      confirm_password: this.state.formData.confirmPassword,
      recaptcha_token: this.props.recaptchaToken,
    }

    this.props.dispatch(userSignup(data))
  }

  render() {
    const { submit } = this.props.classes
    const errors = this.props.errors
    const userErrors = errors.user || {}

    if (this.props.user) {
      return <Redirect to="/console" />
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          id="first-name"
          label="Prenume"
          margin="normal"
          value={this.state.formData.firstName}
          onChange={this.handleChange('firstName')}
          required
          fullWidth
          autoFocus
          error={Boolean(userErrors.first_name)}
          helperText={userErrors.first_name && userErrors.first_name[0]}
        />

        <TextField
          id="last-name"
          label="Nume de familie"
          margin="normal"
          value={this.state.formData.lastName}
          onChange={this.handleChange('lastName')}
          required
          fullWidth
          error={Boolean(userErrors.last_name)}
          helperText={userErrors.last_name && userErrors.last_name[0]}
        />

        <TextField
          id="email"
          label="Email"
          type="email"
          margin="normal"
          value={this.state.formData.email}
          onChange={this.handleChange('email')}
          required
          fullWidth
          error={Boolean(userErrors.email)}
          helperText={userErrors.email && userErrors.email[0]}
        />

        <TextField
          id="password"
          label="Parolă"
          type="password"
          margin="normal"
          value={this.state.formData.password}
          onChange={this.handleChange('password')}
          required
          fullWidth
          error={Boolean(errors.password)}
          helperText={errors.password && errors.password[0]}
        />

        <TextField
          id="confirm-password"
          label="Confirmă parola"
          type="password"
          margin="normal"
          value={this.state.formData.confirmPassword}
          onChange={this.handleChange('confirmPassword')}
          required
          fullWidth
          error={Boolean(errors.confirm_password)}
          helperText={errors.confirm_password && errors.confirm_password[0]}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={submit}
          disabled={Boolean(this.props.isFetching)}
        >
          Creează cont
        </Button>
      </form>
    )
  }
}

const mapStateToProps = ({
  signup: { user, isFetching, errors },
  human: { recaptchaToken, recaptchaIsProcessing },
}) => ({
  user,
  isFetching,
  errors,
  recaptchaToken,
  recaptchaIsProcessing,
})
SignupForm = connect(mapStateToProps)(SignupForm)
SignupForm = withGoogleReCaptcha(SignupForm)
export default withStyles(styles)(SignupForm)
