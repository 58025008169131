import React from 'react'
import DatePicker from 'react-datepicker'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'

import differenceInMinutes from 'date-fns/differenceInMinutes'
import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import isSameDay from 'date-fns/isSameDay'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import EventIcon from '@material-ui/icons/Event'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import InputMask from 'react-input-mask'
import MenuItem from '@material-ui/core/MenuItem'
import RemoveIcon from '@material-ui/icons/Remove'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

import { appointmentCreate } from '../../../actions'

import 'react-datepicker/dist/react-datepicker.css'

const styles = theme => ({
  content: {
    paddingBottom: '10px',
  },
  selectExistingClientButton: {
    marginTop: '10px',
  },
  or: {
    textAlign: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  appointmentForm: {
    marginTop: '20px',
    marginBottom: '5px',
  },
})

const CalendarDatePicker = props => (
  <DatePicker
    selected={props.startDate}
    onChange={props.handleDatePickerChange('startDate')}
    filterDate={props.filterDate}
    dateFormat="yyyy-MM-dd"
    popperPlacement="right-top"
    popperModifiers={{
      offset: {
        enabled: true,
      },
    }}
    customInput={
      <InputMask mask="9999-99-99" value={props.startDate}>
        {() => (
          <TextField
            id="start-time"
            label="Start date"
            margin="normal"
            placeholder="YYYY-MM-DD"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EventIcon color="primary" />
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        )}
      </InputMask>
    }
  />
)

const CalendarTimePicker = props => (
  <DatePicker
    selected={props.time}
    onChange={props.handleDatePickerChange('time')}
    popperPlacement="right-top"
    showTimeSelect
    showTimeSelectOnly
    timeIntervals={30}
    minTime={setHours(setMinutes(new Date(), 0), 8)}
    maxTime={setHours(setMinutes(new Date(), 30), 20)}
    dateFormat="HH:mm"
    timeCaption="Time"
    customInput={
      <InputMask mask="99:99" value={props.time}>
        {() => (
          <TextField
            id="time"
            margin="normal"
            label="Time"
            placeholder="00:00"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
          />
        )}
      </InputMask>
    }
  />
)

class ModalAppointmentForm extends React.Component {
  state = {
    open: false,
    addClient: false,

    formData: {
      clientId:
        (this.props.appointment && this.props.appointment.client_id) || '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      startDate: (this.props.event && this.props.event.start) || '',
      time: (this.props.event && this.props.event.start) || '',
      duration:
        (this.props.event &&
          differenceInMinutes(this.props.event.end, this.props.event.start)) ||
        '60',
    },
  }

  handleClose = () =>
    this.setState({
      open: false,
      addClient: false,
      formData: {
        clientId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        startDate: '',
        time: '',
        duration: '60',
      },
    })

  handleClickOpen = () => this.setState({ open: true })

  handleAddClient = () => {
    this.setState(state => ({ addClient: !state.addClient }))
  }
  handleChange = name => e => {
    const obj = this.state.formData
    obj[name] = e.target.value
    this.setState(obj)
  }

  handleDatePickerChange = name => date => {
    const obj = this.state.formData
    obj[name] = date
    this.setState(obj)
  }

  handleSubmit = e => {
    e.preventDefault()
    const data = {
      appointment: {
        start_date: format(this.state.formData.startDate, 'yyyy-MM-dd'),
        time: format(this.state.formData.time, 'HH:mm:ss'),
        duration: this.state.formData.duration,
      },
    }
    if (this.state.formData.clientId) {
      data.appointment.client_id = this.state.formData.clientId
    }
    if (this.props.appointment) {
      data.appointment.appointment_id = this.props.appointment.appointment_id
    }
    if (this.state.addClient) {
      data.client = {
        first_name: this.state.formData.firstName,
        last_name: this.state.formData.lastName,
      }
      if (this.state.formData.email) {
        data.client.email = this.state.formData.email
      }
      if (this.state.formData.phoneNumber) {
        data.client.phone_numbers = [
          { phone_number: this.state.formData.phoneNumber },
        ]
      }
    }
    const successCallback = () => {
      this.props.enqueueSnackbar(this.props.successMessage, {
        variant: 'info',
        autoHideDuration: 5000,
      })
      const handleClose = this.props.handleClose || this.handleClose
      handleClose()
    }
    this.props.dispatch(appointmentCreate(data, successCallback))
  }

  filterDate = date => {
    const now = new Date()
    if (!isSameDay(date, now)) {
      if (isBefore(date, now)) {
        return false
      }
    }
    return true
  }

  render() {
    const title = this.props.title
    const open = this.props.open || this.state.open
    const handleClose = this.props.handleClose || this.handleClose
    const triggerButton = (
      <Button variant="outlined" size="small" onClick={this.handleClickOpen}>
        <AddIcon fontSize="small" /> Adaugă programare
      </Button>
    )
    const clientSection = (
      <React.Fragment>
        <Collapse in={!this.state.addClient}>
          <FormControl fullWidth>
            <InputLabel htmlFor="client_id">
              Selectează un client existent
            </InputLabel>
            <Select
              value={this.state.formData.clientId}
              onChange={this.handleChange('clientId')}
              inputProps={{ name: 'client_id', id: 'client_id' }}
              error={this.props.errors._schema}
            >
              <MenuItem value="">
                <em>Selectează un client existent.</em>
              </MenuItem>
              {this.props.clients.map(client => (
                <MenuItem value={client.client_id} key={client.client_id}>
                  {client.first_name} {client.last_name}
                </MenuItem>
              ))}
            </Select>
            {this.props.errors._schema && (
              <FormHelperText error={true}>
                {this.props.errors._schema[0]}
              </FormHelperText>
            )}
            <FormHelperText className={this.props.classes.or}>
              sau
            </FormHelperText>
          </FormControl>

          <Button
            color="primary"
            size="small"
            fullWidth
            onClick={this.handleAddClient}
          >
            <AddIcon fontSize="small" /> Adaugă un client nou
          </Button>
        </Collapse>
        <Collapse in={this.state.addClient}>
          <Button
            color="primary"
            size="small"
            fullWidth
            onClick={this.handleAddClient}
            className={this.props.classes.selectExistingClientButton}
          >
            <RemoveIcon fontSize="small" /> Selectează un client deja existent
          </Button>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id="first-name"
                label="Prenume"
                margin="normal"
                value={this.state.formData.firstName}
                onChange={this.handleChange('firstName')}
                required={this.state.addClient}
                error={Boolean(
                  this.props.errors.client &&
                    this.props.errors.client.first_name
                )}
                helperText={
                  this.props.errors.client &&
                  this.props.errors.client.first_name
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="last-name"
                label="Nume de familie"
                margin="normal"
                value={this.state.formData.lastName}
                onChange={this.handleChange('lastName')}
                fullWidth
                required={this.state.addClient}
                error={Boolean(
                  this.props.errors.client && this.props.errors.client.last_name
                )}
                helperText={
                  this.props.errors.client && this.props.errors.client.last_name
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id="email"
                label="Email"
                margin="normal"
                value={this.state.formData.email}
                onChange={this.handleChange('email')}
                error={Boolean(
                  this.props.errors.client && this.props.errors.client.email
                )}
                helperText={
                  this.props.errors.client && this.props.errors.client.email
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <InputMask
                mask="+40 (999) 999 999"
                value={this.state.formData.phoneNumber}
                onChange={this.handleChange('phoneNumber')}
              >
                {() => (
                  <TextField
                    id="phonenumber"
                    label="Telefon"
                    margin="normal"
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>
        </Collapse>
      </React.Fragment>
    )

    const clientSelect = (
      <FormControl fullWidth>
        <InputLabel htmlFor="client_id">Client</InputLabel>
        <Select
          value={this.state.formData.clientId}
          inputProps={{ name: 'client_id', id: 'client_id' }}
          readOnly
        >
          {this.props.clients.map(client => (
            <MenuItem value={client.client_id} key={client.client_id}>
              {client.first_name} {client.last_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )

    return (
      <React.Fragment>
        {!this.props.hideTriggerButton && triggerButton}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          fullWidth
        >
          <form onSubmit={this.handleSubmit}>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent className={this.props.classes.content}>
              {!this.props.hideTriggerButton && clientSection}
              {this.props.hideTriggerButton && clientSelect}
              <Grid
                container
                spacing={3}
                className={this.props.classes.appointmentForm}
              >
                <Grid item xs={4}>
                  <CalendarDatePicker
                    startDate={this.state.formData.startDate}
                    handleDatePickerChange={this.handleDatePickerChange}
                    filterDate={this.filterDate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CalendarTimePicker
                    time={this.state.formData.time}
                    handleDatePickerChange={this.handleDatePickerChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="duration"
                    label="Durata"
                    margin="normal"
                    type="number"
                    error={Boolean(
                      this.props.errors.appointment &&
                        this.props.errors.appointment.duration
                    )}
                    helperText={
                      this.props.errors.appointment &&
                      this.props.errors.appointment.duration
                    }
                    value={this.state.formData.duration}
                    onChange={this.handleChange('duration')}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Anulează</Button>
              <Button
                type="submit"
                color="secondary"
                disabled={Boolean(this.props.isFetching)}
              >
                {title}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({
  client: { clients },
  appointment: { isFetching, errors },
}) => ({ clients, isFetching, errors })

ModalAppointmentForm = connect(mapStateToProps)(ModalAppointmentForm)
ModalAppointmentForm = withSnackbar(ModalAppointmentForm)

export default withStyles(styles)(ModalAppointmentForm)
