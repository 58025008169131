import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

class ModalConfirmationDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>{this.props.message}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose}>Anulează</Button>
          <Button
            type="submit"
            color="secondary"
            onClick={this.props.handleSubmit}
            disabled={Boolean(this.props.isFetching)}
          >
            {this.props.title}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ModalConfirmationDialog
