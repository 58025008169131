import * as Type from '../actions'

export const console = (
  state = {
    sidebarOpen: false,
    practitioner: null,
    clients: [],
  },
  action
) => {
  switch (action.type) {
    case Type.OPEN_DRAWER:
      return { ...state, sidebarOpen: true }
    case Type.CLOSE_DRAWER:
      return { ...state, sidebarOpen: false }
    case Type.CLIENTS_REQUEST_SUCCESS:
      return {
        ...state,
        clients: action.clients,
        practitioner: action.practitioner,
      }
    default:
      return state
  }
}
