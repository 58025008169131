import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'

import { openDrawer, closeDrawer } from '../../actions'

const styles = theme => ({
  header: {
    background:
      'linear-gradient(141deg, rgba(19,27,111,1) 32%, rgba(128,61,171,1) 100%)',
    boxShadow: 'none',
    '& h6': {
      paddingTop: '5px',
    },
    '& button': {
      borderColor: 'rgba(255, 255, 255, .4)',
      color: 'white',
      padding: '8px 12px',
      textTransform: 'capitalize',
      '& svg': {
        marginRight: '6px',
      },
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
})

let Header = ({ sidebarOpen, openDrawer, closeDrawer, classes, children }) => (
  <AppBar position="static" className={classes.header}>
    <Toolbar>
      <IconButton
        color="inherit"
        onClick={() => (sidebarOpen ? closeDrawer() : openDrawer())}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      {children}
    </Toolbar>
  </AppBar>
)

const mapStateToProps = ({ console: { sidebarOpen } }) => ({ sidebarOpen })
const mapDispatchToProps = dispatch => ({
  closeDrawer: () => {
    dispatch(closeDrawer)
  },
  openDrawer: () => {
    dispatch(openDrawer)
  },
})
Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)

export default withStyles(styles)(Header)
