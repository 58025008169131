import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'

import SidebarContent from './SidebarContent'

import { closeDrawer } from '../../actions'

const drawerWidth = 280

const styles = theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#eee',
    boxShadow:
      '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    padding: '20px',
  },
})

let Sidebar = ({ sidebarOpen, closeDrawer, classes }) => (
  <aside className={classes.drawer}>
    <Hidden smUp implementation="css">
      <Drawer
        variant="temporary"
        anchor="left"
        open={sidebarOpen}
        onClose={closeDrawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <SidebarContent />
      </Drawer>
    </Hidden>
    <Hidden xsDown implementation="css">
      <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
        <SidebarContent />
      </Drawer>
    </Hidden>
  </aside>
)

const mapStateToProps = ({ console: { sidebarOpen } }) => ({ sidebarOpen })
const mapDispatchToProps = dispatch => ({
  closeDrawer: () => {
    dispatch(closeDrawer)
  },
})

Sidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)

export default withStyles(styles)(Sidebar)
