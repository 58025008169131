import * as Type from '../actions'

import { addObjectToState } from 'utils'

export const note = (
  state = {
    notes: [],
    isFetching: false,
    isGetFetching: false,
    isDeleteFetching: false,
    errors: {},
  },
  action
) => {
  switch (action.type) {
    case Type.CLIENT_CREATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isGetFetching: false,
        errors: {},
      }
    case Type.NOTE_CREATE_SUCCESS:
      let notes_ = addObjectToState(state.notes, action.note, 'note_id')
      notes_ = notes_.sort((a, b) => (a.created > b.created ? -1 : 1))
      return {
        ...state,
        notes: notes_,
        isFetching: false,
        isGetFetching: false,
        errors: {},
      }
    case Type.NOTE_CREATE_FAILED:
      return {
        ...state,
        isFetching: false,
        isGetFetching: false,
        errors: action.errors,
      }
    case Type.NOTES_REQUEST:
      return {
        ...state,
        isFetching: false,
        isGetFetching: true,
        errors: {},
      }
    case Type.NOTES_REQUEST_FAILED:
      return {
        ...state,
        isGetFetching: false,
      }
    case Type.NOTES_REQUEST_SUCCESS:
      const notes = state.notes.filter(
        note => note.client_id !== action.client_id
      )
      return {
        ...state,
        isGetFetching: false,
        notes: [...notes, ...action.notes],
      }
    case Type.NOTE_DELETE_REQUEST:
      return {
        ...state,
        isDeleteFetching: true,
      }
    case Type.NOTE_DELETE_SUCCESS:
      return {
        ...state,
        notes: state.notes.filter(note => note.note_id !== action.note_id),
        isDeleteFetching: false,
      }
    default:
      return state
  }
}
