import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({
  paper: {
    padding: theme.spacing(6),
    color: theme.palette.text.secondary,
  },
})

const CenteredPaper = ({ xs = 11, md = 8, lg = 6, classes, children }) => (
  <Grid container alignItems="center" justify="center">
    <Grid item xs={xs} md={md} lg={lg}>
      <Paper className={classes.paper}>{children}</Paper>
    </Grid>
  </Grid>
)

export default withStyles(styles)(CenteredPaper)
