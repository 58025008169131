import React, { Component } from 'react'
import { Redirect } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import EmailOutlined from '@material-ui/icons/EmailOutlined'
import LockOutlined from '@material-ui/icons/LockOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

import { recaptchaVerify, recaptchaRequest } from '../../actions'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing(3),
  },
})

class ResetPasswordForm extends Component {
  state = {
    formData: {
      email: '',
    },
  }

  async componentDidMount() {
    await this.triggerRecaptcha()
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.recaptchaToken == null &&
      !this.props.recaptchaIsProcessing
    ) {
      await this.triggerRecaptcha()
    }
  }

  async triggerRecaptcha() {
    this.props.dispatch(recaptchaRequest())
    const token = await this.props.googleReCaptchaProps.executeRecaptcha(
      'login'
    )
    this.props.dispatch(recaptchaVerify(token))
  }

  handleChange = name => e => {
    const obj = this.state.formData
    obj[name] = e.target.value
    this.setState(obj)
  }

  handleSubmit = e => {
    e.preventDefault()
    const data = {
      email: this.state.formData.email,
      recaptcha_token: this.props.recaptchaToken,
    }
  }

  render() {
    const { submit } = this.props.classes

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          id="email"
          label="Email"
          type="email"
          margin="normal"
          value={this.state.formData.email}
          onChange={this.handleChange('email')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EmailOutlined color="primary" />
              </InputAdornment>
            ),
          }}
          required
          fullWidth
          autoFocus
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={submit}
        >
          Reset password
        </Button>
      </form>
    )
  }
}

const mapStateToProps = ({
  human: { recaptchaToken, recaptchaIsProcessing },
}) => ({
  recaptchaToken,
  recaptchaIsProcessing,
})

ResetPasswordForm = connect(mapStateToProps)(ResetPasswordForm)
ResetPasswordForm = withGoogleReCaptcha(ResetPasswordForm)
export default withStyles(styles)(ResetPasswordForm)
