import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Settings from '@material-ui/icons/Settings'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { UserContext } from '../../context'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(),
    cursor: 'pointer',
  },
  content: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  image: {
    width: '30px',
    height: '30px',
    fontSize: '1rem',
    background: theme.palette.primary.main,
  },
  name: {
    paddingLeft: '15px',
    position: 'relative',
    top: '5px',
  },
  more: {
    paddingTop: '3px',
    flexGrow: 1,
    color: '#333',
  },
  listItemIcon: {
    marginRight: 0,
    minWidth: 'initial',
  },
  icon: {
    width: '0.75em',
    height: '0.75em',
  },
})

class AvatarContent extends React.Component {
  state = {
    open: false,
  }

  constructor(props) {
    super(props)
    this.menuRef = React.createRef()
  }
  handleToggle = e => {
    const menuRef = this.menuRef.current
    if (menuRef && menuRef.contains(e.target)) {
      return
    }
    this.setState(state => ({ open: !state.open }))
  }

  render() {
    const { classes } = this.props
    const { open } = this.state
    return (
      <div className={classes.root} onClick={this.handleToggle}>
        <Divider />

        <UserContext.Consumer>
          {user => (
            <Grid container className={classes.content}>
              <Avatar className={classes.image}>
                {user.first_name.substring(0, 1).toUpperCase()}
                {user.last_name.substring(0, 1).toUpperCase()}
              </Avatar>
              <Grid>
                <Typography
                  component="span"
                  className={classes.name}
                  variant="body2"
                >
                  {user.first_name} {user.last_name}
                </Typography>
              </Grid>
              <Grid align="right" className={classes.more}>
                {open ? <ArrowDropUp /> : <ArrowDropDown />}
              </Grid>
            </Grid>
          )}
        </UserContext.Consumer>

        {this.state.open ? (
          <div ref={this.menuRef}>
            <Grow in={open}>
              <MenuList dense disablePadding>
                <MenuItem component={RouterLink} to="/console/settings">
                  <ListItemText
                    primary="Setări"
                    style={{ paddingLeft: '31px' }}
                  />
                  <ListItemIcon className={classes.listItemIcon}>
                    <Settings className={classes.icon} />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem component={RouterLink} to="/logout">
                  <ListItemText
                    primary="Delogare"
                    style={{ paddingLeft: '31px' }}
                  />
                  <ListItemIcon className={classes.listItemIcon}>
                    <ExitToApp className={classes.icon} />
                  </ListItemIcon>
                </MenuItem>
              </MenuList>
            </Grow>
          </div>
        ) : (
          ''
        )}

        <Divider />
      </div>
    )
  }
}

export default withStyles(styles)(AvatarContent)
