import * as Type from './types'
import axios from 'axios'

const getClientsRequest = { type: Type.CLIENTS_REQUEST }
const getClientsRequestFailed = { type: Type.CLIENTS_REQUEST_FAILED }
const getClientsRequestSuccess = (practitioner, clients) => ({
  type: Type.CLIENTS_REQUEST_SUCCESS,
  practitioner,
  clients,
})

export const getClients = () => async dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/clients`
  const authToken = window.localStorage.getItem('authToken')

  dispatch(getClientsRequest)

  try {
    const response = await axios.get(url, {
      headers: { Authorization: `JWT ${authToken}` },
    })
    const practitioner = response.data.result.practitioner
    const clients = response.data.result.clients

    dispatch(getClientsRequestSuccess(practitioner, clients))
  } catch (error) {
    dispatch(getClientsRequestFailed)
  }
}

const clientCreateRequest = { type: Type.CLIENT_CREATE_REQUEST }
const clientCreateSuccess = client => ({
  type: Type.CLIENT_CREATE_SUCCESS,
  client,
})
const clientCreateFailed = errors => ({
  type: Type.CLIENT_CREATE_FAILED,
  errors,
})
export const clientCreate = (data, successCallback) => dispatch => {
  let url = `${process.env.REACT_APP_API_URL}/clients`
  const authToken = window.localStorage.getItem('authToken')

  const client_id = data.client_id
  const request = client_id ? axios.put : axios.post
  url = client_id ? `${url}/${client_id}` : url
  delete data.client_id

  dispatch(clientCreateRequest)

  return request(url, data, {
    headers: { Authorization: `JWT ${authToken}` },
  })
    .then(response => {
      dispatch(clientCreateSuccess(response.data.result.client))
      successCallback()
    })
    .catch(error => {
      const errors = error.response.data.result.errors
      dispatch(clientCreateFailed(errors))
    })
}

const clientDeleteRequest = { type: Type.CLIENT_DELETE_REQUEST }
const clientDeleteSuccess = client_id => ({
  type: Type.CLIENT_DELETE_SUCCESS,
  client_id,
})
export const clientDelete = client_id => dispatch => {
  let url = `${process.env.REACT_APP_API_URL}/clients/${client_id}`
  const authToken = window.localStorage.getItem('authToken')

  dispatch(clientDeleteRequest)
  return axios
    .delete(url, {
      headers: { Authorization: `JWT ${authToken}` },
    })
    .then(() => dispatch(clientDeleteSuccess(client_id)))
}

