import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { userLogout } from '../../actions'

class Logout extends Component {
    componentDidMount() {
        this.props.dispatch(userLogout())
    }
    render() {
        return <Redirect to="/login" />
    }
}

Logout = connect()(Logout)
export default Logout
