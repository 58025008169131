import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import EmailOutlined from '@material-ui/icons/EmailOutlined'
import LockOutlined from '@material-ui/icons/LockOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

import { userLogin, recaptchaVerify, recaptchaRequest } from '../../actions'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

const styles = theme => ({
  submit: {
    marginTop: theme.spacing(3),
  },
})

class LoginForm extends Component {
  state = {
    formData: {
      email: '',
      password: '',
    },
  }

  async componentDidMount() {
    await this.triggerRecaptcha()
  }

  async componentDidUpdate(prevProps) {
    if (this.props.recaptchaToken == null && !this.props.recaptchaIsProcessing) {
      await this.triggerRecaptcha();
    }
  }

  async triggerRecaptcha() {
    this.props.dispatch(recaptchaRequest())
    const token = await this.props.googleReCaptchaProps.executeRecaptcha('login')
    this.props.dispatch(recaptchaVerify(token))
  }

  handleChange = name => e => {
    const obj = this.state.formData
    obj[name] = e.target.value
    this.setState(obj)
  }

  handleSubmit = e => {
    e.preventDefault()
    const data = {
      email: this.state.formData.email,
      password: this.state.formData.password,
      recaptcha_token: this.props.recaptchaToken,
    }
    this.props.dispatch(userLogin(data))
  }

  render() {
    const { submit } = this.props.classes
    const errors = this.props.errors

    if (this.props.user) {
      return <Redirect to="/console" />
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          id="email"
          label="Email"
          type="email"
          margin="normal"
          value={this.state.formData.email}
          onChange={this.handleChange('email')}
          error={Boolean(errors.email)}
          helperText={errors.email && errors.email[0]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EmailOutlined color="primary" />
              </InputAdornment>
            ),
          }}
          required
          fullWidth
          autoFocus
        />

        <TextField
          id="password"
          label="Password"
          type="password"
          margin="normal"
          value={this.state.formData.password}
          onChange={this.handleChange('password')}
          error={Boolean(errors.password)}
          helperText={errors.password && errors.password[0]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LockOutlined color="primary" />
              </InputAdornment>
            ),
          }}
          required
          fullWidth
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={submit}
          disabled={Boolean(this.props.isFetching)}
        >
          Sign in
        </Button>
      </form>
    )
  }
}

const mapStateToProps = ({
  login: { user, isFetching, errors },
  human: { recaptchaToken, recaptchaIsProcessing },
}) => ({
  user,
  isFetching,
  errors,
  recaptchaToken,
  recaptchaIsProcessing,
})
LoginForm = connect(mapStateToProps)(LoginForm)
LoginForm = withGoogleReCaptcha(LoginForm)
export default withStyles(styles)(LoginForm)
