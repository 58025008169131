import React from 'react'

import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/differenceInMinutes'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

class ModalViewAppointment extends React.Component {
  render() {
    const { appointment, client, event } = this.props

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ color: appointment.color_id }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {appointment.title}
            </Grid>
            <Grid item xs={6} align="right">
              <IconButton
                size="small"
                style={{ marginRight: '10px' }}
                onClick={this.props.handleEditDialog}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={this.props.handleDeleteConfirmation}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Start date
                </TableCell>
                <TableCell>
                  {event.start && format(event.start, 'iiii, dd.MM.yyyy H:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  End date
                </TableCell>
                <TableCell>
                  {event.end && format(event.end, 'iiii, dd.MM.yyyy H:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Duration
                </TableCell>
                <TableCell>
                  {event.start &&
                    event.end &&
                    differenceInMinutes(event.end, event.start)}{' '}
                  minutes
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Email
                </TableCell>
                <TableCell>
                  <Link href={`mailto:${client.email}`}>{client.email}</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Phone number
                </TableCell>
                <TableCell>
                  {client &&
                    client.phone_numbers &&
                    client.phone_numbers.map(number => number.phone_number)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    )
  }
}

export default ModalViewAppointment
