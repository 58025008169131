import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import AccountSettings from './AccountSettings'
import ChangePassword from './AccountSettings/ChangePassword'
import Calendar from './Calendar'
import ClientDetails from './Clients/ClientDetails'
import ClientNotes from './Clients/ClientNotes'
import Clients from './Clients'
import Sidebar from './Sidebar'

import { getAppointments, getClients } from 'actions'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  mainPanel: {
    flexGrow: 1,
  },
  mainContent: {
    padding: theme.spacing(3),
  },
})

class Console extends React.Component {
  componentDidMount() {
    this.props.dispatch(getAppointments())
    this.props.dispatch(getClients())
  }
  render() {
    const { classes, match } = this.props
    return (
      <div className={classes.root}>
        <Sidebar />
        <div className={classes.mainPanel}>
          <Route exact path={`${match.path}`} component={Calendar} />
          <Route
            exact
            path={`${match.path}/settings`}
            component={AccountSettings}
          />
          <Route
            exact
            path={`${match.path}/settings/password/change`}
            component={ChangePassword}
          />
          <Route exact path={`${match.path}/clients`} component={Clients} />
          <Route
            exact
            path={`${match.path}/clients/:id`}
            component={ClientDetails}
          />
          <Route
            exact
            path={`${match.path}/clients/:id/notes`}
            component={ClientNotes}
          />
        </div>
      </div>
    )
  }
}

Console = connect()(Console)

export default withStyles(styles)(Console)
